/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, Fragment, useState } from 'react';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import { useI18n } from 'src/i18n/hooks';
import { useHeaderTitle } from 'src/contexts/header-context';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { attendanceSlots, updateInstituteSettings } from 'src/graphql/attendance';
import { useMutation, useQuery } from '@apollo/client';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ADD_SUCCESS } from 'src/constant/message';
import { ERROR, FileCategory, OrientationLabel, SUCCESS } from 'src/constant';
import { userInformation } from 'src/utils/manageState';
import MarkAttendanceSettingsForm from 'src/components/molecules/Attendance/AttendanceSettingForm';
import { useForm } from 'react-hook-form';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import { createDocumentObject } from 'src/utils/utility';
import { getProtectedImage } from 'src/components/services/image-service';
import styled from 'styled-components/native';
import { getDocumentId } from './helpers';

export default function AttendenceSettings() {
  const { setAlertDetails } = useAlertSystem();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const currentUserObject = userInformation();
  const { userDetail }: any = currentUserObject;
  const [documentsRemoved, setDocumentsRemoved] = useState({});
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    watch,
  } = useForm();
  const attendanceType = [
    { value: 0, label: t('fullDay.label'), type: 'DAY_WISE' },
    { value: 1, label: t('slots.label'), type: 'SLOT_WISE' },
    { value: 2, label: t('subjects.label'), type: 'CLASS_WISE' },
  ];
  interface selectedAttendence {
    type: string;
    label: string;
    value: number;
  }

  interface detailsObject {
    showOnboarding: boolean;
    allowProfileEdit: boolean;
  }

  const [selectedAttendenceType, setSelectedAttendenceType] = useState<selectedAttendence | null>(
    null,
  );

  const [numberOfSlots, setnumberOfSlots] = useState<number>(0);
  const [studentDetails, setStudentDetails] = useState<detailsObject>({
    showOnboarding: false,
    allowProfileEdit: false,
  });
  const [employeeDetails, setEmployeeDetails] = useState<detailsObject>({
    showOnboarding: false,
    allowProfileEdit: false,
  });
  const [fileObjects, setFileObjects] = useState<any>({});
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [canClick, setCanClick] = useState<boolean>(true);

  const {
    data: slotsData,
    loading: slotsDataloading,
    refetch,
  } = useQuery(attendanceSlots, {
    fetchPolicy: 'network-only',
  });

  const [updateInstitueAttendenceSettingMutateFunction] = useMutation(updateInstituteSettings);

  async function setTitle() {
    setHeading([
      {
        text: t('settings.label'),
        url: '',
      },
    ]);
  }

  async function handleSave(payload: any) {
    setCanClick(false);

    try {
      if (payload?.grNoPrefix && !payload?.startingGRNo) {
        throw new Error("Starting G.R. Number is required to accompany prefix");
      }

    const input = {
      id: userDetail?.institute,
      attendanceType: attendanceType[payload.attendanceType.value].type,
      students: {
        showOnboarding: studentDetails.showOnboarding,
        allowProfileEdit: studentDetails.allowProfileEdit,
      },
      employee: {
        showOnboarding: employeeDetails.showOnboarding,
        allowProfileEdit: employeeDetails.allowProfileEdit,
      },
      ...(payload?.logo && !payload.logo?.id
        ? { logo: await createDocumentObject(payload.logo, FileCategory.Identity) }
        : {}),
      ...(payload?.stamp && !payload.stamp?.id
        ? { stamp: await createDocumentObject(payload.stamp, FileCategory.Identity) }
        : {}),
      ...(payload?.registrationNoLabel
        ? { registrationNoLabelValue: payload.registrationNoLabel }
        : {}),
      ...(payload?.idCardOrientation?.value
        ? { idCardOrientation: payload.idCardOrientation.value }
        : {}),

      ...(payload?.idCardAuthorityIdentityName
        ? {
            idCardSignature: {
              holderName: payload.idCardAuthorityIdentityName,
              ...(payload?.idCardAuthorityIdentitySignature &&
              !payload.idCardAuthorityIdentitySignature?.id
                ? {
                    signatureImage: await createDocumentObject(
                      payload.idCardAuthorityIdentitySignature,
                      FileCategory.Identity,
                    ),
                  }
                : {}),
            },
          }
        : {}),

      ...(payload?.leavingCertificateAuthorityIdentityName1
        ? {
            leavingCertcSignature1: {
              holderName: payload.leavingCertificateAuthorityIdentityName1,
              ...(payload?.leavingCertificateAuthorityIdentitySignature1 &&
              !payload.leavingCertificateAuthorityIdentitySignature1?.id
                ? {
                    signatureImage: await createDocumentObject(
                      payload.leavingCertificateAuthorityIdentitySignature1,
                      FileCategory.Identity,
                    ),
                  }
                : {}),
            },
          }
        : {}),

      ...(payload?.leavingCertificateAuthorityIdentityName2
        ? {
            leavingCertcSignature2: {
              holderName: payload.leavingCertificateAuthorityIdentityName2,
              ...(payload?.leavingCertificateAuthorityIdentitySignature2 &&
              !payload.leavingCertificateAuthorityIdentitySignature2?.id
                ? {
                    signatureImage: await createDocumentObject(
                      payload.leavingCertificateAuthorityIdentitySignature2,
                      FileCategory.Identity,
                    ),
                  }
                : {}),
            },
          }
        : {}),

      ...(payload?.bonafideCertificateAuthorityIdentityName1
        ? {
            bonafiedCertSignature1: {
              holderName: payload.bonafideCertificateAuthorityIdentityName1,
              ...(payload?.bonafideCertificateAuthorityIdentitySignature1 &&
              !payload.bonafideCertificateAuthorityIdentitySignature1?.id
                ? {
                    signatureImage: await createDocumentObject(
                      payload.bonafideCertificateAuthorityIdentitySignature1,
                      FileCategory.Identity,
                    ),
                  }
                : {}),
            },
          }
        : {}),

      ...(payload?.bonafideCertificateAuthorityIdentityName2
        ? {
            bonafiedCertSignature2: {
              holderName: payload.bonafideCertificateAuthorityIdentityName2,
              ...(payload?.bonafideCertificateAuthorityIdentitySignature2 &&
              !payload.bonafideCertificateAuthorityIdentitySignature2?.id
                ? {
                    signatureImage: await createDocumentObject(
                      payload.bonafideCertificateAuthorityIdentitySignature2,
                      FileCategory.Identity,
                    ),
                  }
                : {}),
            },
          }
        : {}),

      ...(payload?.feeReceiptAuthorityIdentityName
        ? {
            feeReceiptSignature: {
              holderName: payload.feeReceiptAuthorityIdentityName,
              ...(payload?.feeReceiptAuthorityIdentitySignature &&
              !payload.feeReceiptAuthorityIdentitySignature?.id
                ? {
                    signatureImage: await createDocumentObject(
                      payload.feeReceiptAuthorityIdentitySignature,
                      FileCategory.Identity,
                    ),
                  }
                : {}),
            },
          }
        : {}),
      grNoPrefix: payload?.grNoPrefix || null,
      startingGRNo: parseFloat(payload?.startingGRNo) || null,
    };

    const documentHeader = {
      ...(payload.header ? { mainHeader: payload.header } : {}),
      ...(payload.subHeader ? { subHeader: payload.subHeader } : {}),
      ...(payload.superHeader ? { superHeader: payload.superHeader } : {}),
      ...(payload.emailID ? { email: payload.emailID } : {}),
      ...(payload.contact ? { contact: payload.contact } : {}),
      ...(payload.website ? { website: payload.website } : {}),
      ...(payload.address ? { address: payload.address } : {}),
    };

    if (documentHeader && Object.keys(documentHeader).length > 0) {
      input['documentHeader'] = documentHeader;
    }

      const result = await updateInstitueAttendenceSettingMutateFunction({
        variables: {
          payload:
            payload.attendanceType.value === 1
              ? {
                  ...input,
                  numberOfSlots: payload.numberofslots.value,
                }
              : {
                  ...input,
                },
          remove: documentsRemoved,
        },
      });

      if (result) {
        setCanClick(true);
        setAlertDetails({ message: ADD_SUCCESS.UPDATE_INSTITUTE_SETTINGS, level: SUCCESS });
        setDocumentsRemoved({});
        refetch();
      }
    } catch (err) {
      setCanClick(true);
      setAlertDetails({ message: err?.message, level: ERROR });
    }
    setTimeout(() => {
      setAlertDetails({ message: '', level: '' });
    }, 2500);
  }

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    if (!slotsDataloading && slotsData) {
      if (!slotsData?.institute.settings) {
        setnumberOfSlots(0);
        setSelectedAttendenceType({} as selectedAttendence);
      } else {
        const settings = slotsData.institute.settings;
        setDocuments(settings);
        if (settings.attendanceType === 'DAY_WISE') {
          setSelectedAttendenceType(attendanceType[0]);
        } else if (settings.attendanceType === 'SLOT_WISE') {
          setnumberOfSlots(settings.attendanceSlots.length);
          setSelectedAttendenceType(attendanceType[1]);
        } else {
          setSelectedAttendenceType(attendanceType[2]);
        }

        const studentData = settings?.students;
        const employeeData = settings?.employee;
        setStudentDetails({
          showOnboarding: studentData?.showOnboarding,
          allowProfileEdit: studentData?.allowProfileEdit,
        });
        setEmployeeDetails({
          showOnboarding: employeeData?.showOnboarding,
          allowProfileEdit: employeeData?.allowProfileEdit,
        });
        if (settings?.documentHeader?.mainHeader)
          setValue('header', settings.documentHeader.mainHeader);
        if (settings?.documentHeader?.subHeader)
          setValue('subHeader', settings.documentHeader.subHeader);
        if (settings?.documentHeader?.superHeader)
          setValue('superHeader', settings.documentHeader.superHeader);
        if (settings?.documentHeader?.email) setValue('emailID', settings.documentHeader.email);
        if (settings?.documentHeader?.contact) setValue('contact', settings.documentHeader.contact);
        if (settings?.documentHeader?.website) setValue('website', settings.documentHeader.website);
        if (settings?.documentHeader?.address) setValue('address', settings.documentHeader.address);
        if (settings?.registrationNoLabelValue)
          setValue('registrationNoLabel', settings.registrationNoLabelValue);
        if (settings?.idCardOrientation)
          setValue('idCardOrientation', {
            label: OrientationLabel[settings.idCardOrientation],
            value: settings.idCardOrientation,
          });

        if (settings?.idCardSignature?.holderName)
          setValue('idCardAuthorityIdentityName', settings.idCardSignature.holderName);
        if (settings?.leavingCertcSignature1?.holderName)
          setValue(
            'leavingCertificateAuthorityIdentityName1',
            settings.leavingCertcSignature1.holderName,
          );
        if (settings?.leavingCertcSignature2?.holderName)
          setValue(
            'leavingCertificateAuthorityIdentityName2',
            settings.leavingCertcSignature2.holderName,
          );
        if (settings?.bonafiedCertSignature1?.holderName)
          setValue(
            'bonafideCertificateAuthorityIdentityName1',
            settings.bonafiedCertSignature1.holderName,
          );
        if (settings?.bonafiedCertSignature2?.holderName)
          setValue(
            'bonafideCertificateAuthorityIdentityName2',
            settings.bonafiedCertSignature2.holderName,
          );
        if (settings?.feeReceiptSignature?.holderName)
          setValue('feeReceiptAuthorityIdentityName', settings.feeReceiptSignature.holderName);

        if (settings?.startingGRNo) {
          setValue('startingGRNo', settings.startingGRNo)
        };
        if (settings?.grNoPrefix) {
          setValue('grNoPrefix', settings.grNoPrefix)
        };
      }
    }
  }, [slotsDataloading]);

  async function setDocuments(settings: any) {
    setDocumentLoading(true);
    let fileobj = { ...fileObjects };
    if (settings?.logo) {
      const logoDetails = {
        ...settings.logo,
        uri: await getProtectedImage(settings.logo.uri),
      };
      setValue('logo', logoDetails);
      fileobj['logo'] = logoDetails;
    }

    if (settings?.stamp) {
      const stampDetails = {
        ...settings.stamp,
        uri: await getProtectedImage(settings.stamp.uri),
      };
      setValue('stamp', stampDetails);
      fileobj['stamp'] = stampDetails;
    }

    if (settings?.idCardSignature?.signatureImage) {
      const idCardDetails = {
        ...settings.idCardSignature.signatureImage,
        uri: await getProtectedImage(settings.idCardSignature.signatureImage.uri),
      };
      setValue('idCardAuthorityIdentitySignature', idCardDetails);
      fileobj['idCardAuthorityIdentitySignature'] = idCardDetails;
    }

    if (settings?.leavingCertcSignature1?.signatureImage) {
      const leavingCertificateDetails1 = {
        ...settings.leavingCertcSignature1.signatureImage,
        uri: await getProtectedImage(settings.leavingCertcSignature1.signatureImage.uri),
      };
      setValue('leavingCertificateAuthorityIdentitySignature1', leavingCertificateDetails1);
      fileobj['leavingCertificateAuthorityIdentitySignature1'] = leavingCertificateDetails1;
    }

    if (settings?.leavingCertcSignature2?.signatureImage) {
      const leavingCertificateDetails2 = {
        ...settings.leavingCertcSignature2.signatureImage,
        uri: await getProtectedImage(settings.leavingCertcSignature2.signatureImage.uri),
      };
      setValue('leavingCertificateAuthorityIdentitySignature2', leavingCertificateDetails2);
      fileobj['leavingCertificateAuthorityIdentitySignature2'] = leavingCertificateDetails2;
    }

    if (settings?.bonafiedCertSignature1?.signatureImage) {
      const bonafideCertificateDetails1 = {
        ...settings.bonafiedCertSignature1.signatureImage,
        uri: await getProtectedImage(settings.bonafiedCertSignature1.signatureImage.uri),
      };
      setValue('bonafideCertificateAuthorityIdentitySignature1', bonafideCertificateDetails1);
      fileobj['bonafideCertificateAuthorityIdentitySignature1'] = bonafideCertificateDetails1;
    }

    if (settings?.bonafiedCertSignature2?.signatureImage) {
      const bonafideCertificateDetails2 = {
        ...settings.bonafiedCertSignature2.signatureImage,
        uri: await getProtectedImage(settings.bonafiedCertSignature2.signatureImage.uri),
      };
      setValue('bonafideCertificateAuthorityIdentitySignature2', bonafideCertificateDetails2);
      fileobj['bonafideCertificateAuthorityIdentitySignature2'] = bonafideCertificateDetails2;
    }

    if (settings?.feeReceiptSignature?.signatureImage) {
      const feeReceiptDetails = {
        ...settings.feeReceiptSignature.signatureImage,
        uri: await getProtectedImage(settings.feeReceiptSignature.signatureImage.uri),
      };
      setValue('feeReceiptAuthorityIdentitySignature', feeReceiptDetails);
      fileobj['feeReceiptAuthorityIdentitySignature'] = feeReceiptDetails;
    }

    setFileObjects(fileobj);
    setDocumentLoading(false);
  }

  if (selectedAttendenceType === null || documentLoading) {
    return (
      <LoadContentWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 450,
          }}>
          <LoaderSpinner />
        </div>
      </LoadContentWrapper>
    );
  }

  const handleFilesAttach = (id: any) => {
    return (files: CustomReactNativeFile[]) => {
      if (files?.length > 0) {
        let file: any = files[0];
        setValue(id, file);
        maintainFileObject(file, id);
        clearErrors(id);
      }
    };
  };

  function maintainFileObject(data: object, id: string) {
    let fileobj = { ...fileObjects };
    fileobj[id] = data;
    setFileObjects(fileobj);
  }

  const handleFileRemove = (id: string) => {
    const docId = fileObjects[id]?.id;
    if (docId) {
      const documents = { ...documentsRemoved };
      documents[`${getDocumentId(id)}`] = docId;
      setDocumentsRemoved(documents);
    }
    setValue(id, {});
    const fileObj = { ...fileObjects };
    delete fileObj[id];
    setFileObjects(fileObj);
  };

  return (
    <LoadContentWrapper style={{ marginTop: '22px', overflow: 'scroll' }}>
      <MarkAttendanceSettingsForm
        control={control}
        errors={errors}
        reset={reset}
        setValue={setValue}
        numberOfSlots={numberOfSlots}
        selectedAttendenceType={selectedAttendenceType}
        handleFilesAttach={handleFilesAttach}
        handleFileRemove={handleFileRemove}
        fileObjects={fileObjects}
        studentDetails={studentDetails}
        setStudentDetails={setStudentDetails}
        employeeDetails={employeeDetails}
        setEmployeeDetails={setEmployeeDetails}
        watch={watch}
      />

      <SaveBtn
        canLoad={!canClick}
        label={t('save.label')}
        onPress={handleSubmit(handleSave)}
      />
    </LoadContentWrapper>
  );
}

const SaveBtn = styled(SecondaryBtn)`
  padding-vertical: 8px; 
  margin-top: 0;
  height: 32px;
  position: fixed;
    top: 115px;
    right: 60px;
`;
